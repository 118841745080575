import Vue from 'vue'
// 自定义 重写主题色

import { BootstrapVueIcons,BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css';

import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'

import('@/assets/style/bootstrap.scss')
Vue.use(BootstrapVueIcons)
Vue.use(BootstrapVue)

<script>
import TopBar from "@/components/topBar.vue";
import {getToken} from "@/utils/token/jwtService";

export default {
  name: 'hgHome',
  // eslint-disable-next-line vue/no-unused-components
  components: {TopBar},
  data() {
    return {
      urlList: [],
      loading: true,
      tabIndex: 0,
      tabList: [
        {
          name: '岗前培训',
        },
        {
          name: '船员面试',
        },
        {
          name: '提升学习',
        },
        {
          name: '晋升考核',
        }
      ]
    }
  },
  computed: {
    // 获取用户产品权限
    getUserProductList() {
      let user = window.$global.userDetails
      if (!user) return []
      return user.productExpired
    },
// 获取产品列表
    getProductList() {
      let list = window.$global.ProductList || [];
      // 根据 index 字段排序，index 为 null 的排在最后
      list.sort((a, b) => {
        if (a.idx === null && b.idx === null) return 0;
        if (a.idx === null) return 1;
        if (b.idx === null) return -1;
        return a.idx - b.idx;
      })

      return list
    }
  },

  created() {
    this.urlList.push(require('@/assets/image/carousel-1.png'))
    setTimeout(() => {
      this.loading = false
    }, 2500)
  },
  methods: {
    navClick() {
      // this.$notify({
      //   title: '职务晋升',
      //   position: 'center', // 设置为center使其居中
      //   offset: 0, // 可以通过调整offset来调整垂直位置
      //   dangerouslyUseHTMLString: true,
      //   message: '<strong>敬请期待</strong>',
      //   onClick: (() => {
      //     // this.productsShow = true
      //   })
      // })

      this.$bvToast.toast(`敬请期待`, {
        title: `职务晋升`,
        toaster: 'b-toaster-top-center',
        autoHideDelay:'2000',
        solid: true
      })
    },
    selectTab(index) {
      this.tabIndex = index
      // 船员面试
      if (index === 1) {
        let jwt = getToken()
        if (!jwt) {
          this.$router.push('/login')
          return;
        }
        window.open('https://www.xmhgapi.com/exercise?token=' + jwt, '_blank');
      }
    },
    getImage(id) {
      return {
        backgroundImage: `url(${require(`@/assets/tools/${id}.png`)})`
      };
    },
    // 跳至产品页面
    skipToProductPage(item) {
      if (!item.url) {
        this.$bvToast.toast('敬请期待', {
          title: item.name,
          toaster: 'b-toaster-top-center',
          solid: true,
          autoHideDelay:'2000'
        })
        return
      }
      this.$router.push({name: 'details', params: {product: item}})
    }
  }
}
</script>

<template>
  <div>
    <el-container direction="vertical" class="home-container">
      <b-carousel
          id="carousel-fade"
      >
        <!--          :interval="2000"-->
        <b-carousel-slide
            caption=""
            :img-src="item"
            v-for="(item, index) in urlList" :key="index"
        ></b-carousel-slide>

      </b-carousel>

      <div class="hover-group position-absolute
       p-5 d-md-flex justify-content-lg-end
       justify-content-md-end d-none mt-0 mt-lg-5 mr-lg-5">
        <b-list-group style="border-radius: 12px">
          <b-list-group-item v-for="(item, index) in tabList"
                             @click="selectTab(index)"
                             :key="item.name"
                             :class="['p-lg-4', 'p-md-2','p-sm-0 p-2', 'list-group-item',
                              { 'list-group-item-active': index === tabIndex }]">{{ item.name }}
          </b-list-group-item>

        </b-list-group>
      </div>


      <b-container fluid class="p-3">
        <!--  求职准备    -->
        <b-row class="p-md-5">
          <b-col>
            <p class="head-title">
              职务晋升
            </p>
          </b-col>
        </b-row>

        <b-row class="m-auto p-2 5" align-content="center" align-h="center">
          <b-col :cols="12" :lg="4" :md="12">
            <div @click="navClick(1)">
              <b-img rounded :src="require(`@/assets/image/nav-item-1.svg`)" class="w-100 h-100"/>
              <div class="nav-item-box">
                <div class="nav-item-text">驾驶员业务</div>
                <div class="nav-item-text-sm">立即学习</div>
              </div>
            </div>
          </b-col>

          <b-col :cols="12" :lg="4" :md="12">
            <div @click="navClick(1)">
              <b-img rounded :src="require(`@/assets/image/nav-item-2.svg`)" class="w-100 h-100"/>
              <div class="nav-item-box">
                <div class="nav-item-text">轮机员业务</div>
                <div class="nav-item-text-sm">立即学习</div>
              </div>
            </div>
          </b-col>
        </b-row>

      </b-container>



    </el-container>

    <!--获取数据加载动画-->
    <div v-if="loading && getProductList.length === 0" class="d-flex align-items-center">
      <b-spinner variant="secondary" label="Loading..." class="ml-auto"></b-spinner>
    </div>


    <!--产品项-->
    <b-row class="p-2  mt-4 p-md-5">
      <b-col>
        <p class="head-title">
          学习模块
        </p>
      </b-col>
    </b-row>
    <!--动画过度标签-->
    <transition name="el-fade-in-linear">
      <!-- 九宫格布局 -->
      <b-container  v-show="getProductList.length !== 0"
                    class="collapseItem justify-content-center ml-auto">
        <b-row class="grid-container">
          <b-col v-for="(item, index) in getProductList" :key="index" class="grid-item">
            <div class="product-item p-2 pb-3">
              <b-img  @click="skipToProductPage(item)" rounded :src="require(`@/assets/tools/${item.id}.png`)" class="w-100 h-100"/>
              <div class="product-info">
                <span class="product-name pt-2 d-none d-sm-block">{{ item.name }}</span>
                <div class="pt-2 d-none d-md-block">
                  <b-button pill class="product-btn" size="sm" @click.prevent="skipToProductPage(item)">在线练习</b-button>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </transition>
    <div class="home-footer"></div>
  </div>
</template>

<style scoped>


.collapseItem {
  flex-wrap: wrap;
  border-radius: 12px;
  box-shadow: #9E9E9E 0 0 1px 0;
  background: white;
  /*padding-bottom: 150px;*/
}

.home-container {
  width: 100%;
  margin: 0 auto;
  align-items: center;
}

.product-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
}

.product-name {
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: bold;
  color: #000913;
}


.product-item {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0;
}

.grid-item {
  border-right: 1px solid #ddd;
  box-sizing: border-box;
  padding: 2px;
}

.product-btn {
  color: #000913;
  background-color: transparent;
  border: #000913 solid 1px;
  font-weight: bold;
}

.hover-group {
  width: 100%;
  text-align: center;
  z-index: 100;
}

.list-group-item-active {
  background-color: rgba(214, 231, 255, 0.88) !important;
  /*border-top: none;*/
  border-bottom: #2F5CD6 solid 3px;
  color: #171717;
  font-weight: bold;
  cursor: pointer;
  font-size: 19pt;
  max-width: 260px;
  min-width: 300px;
  max-height: 110px;
}


.list-group-item {
  background-color: rgba(255, 255, 255, 0.62);
  color: black;
  cursor: pointer;
  font-weight: bold;
  font-size: 19pt;
  max-width: 260px;
  min-width: 300px;
  max-height: 110px;
  transition: all .1s linear;
}

.list-group-item:hover {
  background-color: #D6E7FF !important;
  transition: all .1s linear;
  color: #171717;
  font-weight: bold;
  cursor: pointer;
  font-size: 19pt;
  max-width: 260px;
  min-width: 300px;
  max-height: 110px;
}

.head-title {
  font-size: 20pt;
  font-weight: bold;
  text-align: center;
}

.nav-item-box {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  cursor: pointer;
}

.nav-item-text {
  font-size: 1.5rem; /* 根据需要调整字体大小 */
  font-weight: bold;
}

.nav-item-text-sm {
  font-size: 1rem; /* 根据需要调整字体大小 */
  color: rgba(0, 0, 0, 0.62);
}
</style>

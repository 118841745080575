<script>
import * as req from "@/utils/request";

export default {
  name: 'topBar',
  data() {
    return {
      userDialogVisible: false,
      searchMode: 1
    }
  },
  computed: {
    isIndex() {
      return this.$route.name === 'Index'
    },
    getShoppingSize() {
      return window.$global.shoppingCart.length
    },
    userData() {
      let sysuser = window.$global.userDetails

      if (!sysuser) return null

      return sysuser.sysUser
    },
    getUserName() {
      if (!this.userData) {
        return '获取中..'
      }
      let name = this.userData.nickname
      if (name.length <= 15) {
        return name
      }
      return name.substring(0, 13).concat('...')
    },
    getHeadImg() {
      let user = this.userData
      if (!user) {
        return 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'
      }

      if (!user.headImg) {
        return 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'
      }
      return user.headImg
    },
    showBtn() {
      let name = this.$route.name
      if (name === 'Index') {
        return true
      }
      return false
    }
  },
  methods: {
    handleIconClick() {

    },
    toRouter() {
      this.$router.push('/shopping')
    },
    doLogout() {
      this.$confirm('确定退出当前账号吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let sendData = {}
        sendData.data = null
        sendData.fn = this.doLogoutFn
        sendData.vm = this
        req.Get('logout', sendData)
      }).catch(() => {
      });
    },
    doLogoutFn(data) {
      this.$router.push('/login')
      this.$message.success('退出成功')
    },
    home() {
      this.$router.push('/index')
    },
    goToSettings() {
      this.$router.push('/setting')
    },
    myStudies() {
      this.$router.push('/student')
    }
  }
}
</script>

<template>
  <b-navbar :fixed="isIndex ? 'top':''" sticky toggleable="md" class="m-auto bg-white navbar-custom-class navbar-light">
    <b-container fluid>
    <b-navbar-nav href="#"  @click="home" class="pointer">
      <img  width="30" height="30" src="@/assets/image/icon.png" class="d-inline-block align-top align-self-center">
      <div class="navbar-left-context ml-2 d-block">
        <div class="d-none d-md-flex">泓庚航海</div>
        <div class="d-none d-lg-flex">HONGGENG MARINE</div>
      </div>
    </b-navbar-nav>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse  id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-4 m-2 d-none d-md-flex">
        <b-button class="btn-90-w" href="#" variant="primary" @click="home">首页</b-button>
        <b-button class="btn-90-w ml-2 bg-transparent border-0 text-dark d-none d-md-block" type="link" style="width: 90px" href="#">课程</b-button>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto" justified>
          <el-input class="navbar-search" placeholder="搜索..">
            <i
                class="el-icon-search el-input__icon"
                slot="suffix"
                @click="handleIconClick">
            </i>
          </el-input>


        <b-nav-item-dropdown right>
          <!-- Using 'button-content' slot -->
          <template v-slot:button-content>
            <em v-if="getUserName">{{ getUserName }}</em>
            <b-button-group v-else>
              <b-button variant="primary">登录</b-button>
              <b-button variant="primary">注册</b-button>
            </b-button-group>
          </template>
          <b-dropdown-item href="#" @click="goToSettings">个人信息</b-dropdown-item>
          <b-dropdown-item href="#" @click="doLogout">退出</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
    </b-container>
  </b-navbar>

  <!--  <div  id="header-x">
      &lt;!&ndash; Image and text &ndash;&gt;
      <b-navbar sticky toggleable="md">
        <b-container fluid style="background: transparent">
          <b-navbar-brand href="#" class="d-none d-lg-block d-sm-none d-md-none" @click="$router.push('/index')">
            <div class="left-box">航海练习课堂</div>
            &lt;!&ndash;          <div v-if="showBtn" class="left-box-2 d-none d-md-block">我的职业课堂</div>&ndash;&gt;
          </b-navbar-brand>
          &lt;!&ndash;        <div v-if="getRouterName" class="d-lg-flex d-none">&ndash;&gt;
          &lt;!&ndash;          <b-navbar-brand>&ndash;&gt;
          &lt;!&ndash;              TODO&ndash;&gt;
          &lt;!&ndash;            <el-dropdown>&ndash;&gt;
          &lt;!&ndash;  <span class="el-dropdown-link nav-light">&ndash;&gt;
          &lt;!&ndash;    课程分类<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>&ndash;&gt;
          &lt;!&ndash;  </span>&ndash;&gt;
          &lt;!&ndash;              <el-dropdown-menu slot="dropdown">&ndash;&gt;
          &lt;!&ndash;                <el-dropdown-item>课程一</el-dropdown-item>&ndash;&gt;
          &lt;!&ndash;                <el-dropdown-item divided>课程二</el-dropdown-item>&ndash;&gt;
          &lt;!&ndash;              </el-dropdown-menu>&ndash;&gt;
          &lt;!&ndash;            </el-dropdown>&ndash;&gt;

          &lt;!&ndash;          </b-navbar-brand>&ndash;&gt;

          &lt;!&ndash;          <b-navbar-brand class="nav-light">&ndash;&gt;
          &lt;!&ndash;            <span class="nav-light">&ndash;&gt;
          &lt;!&ndash;              微专业&ndash;&gt;
          &lt;!&ndash;            </span>&ndash;&gt;
          &lt;!&ndash;          </b-navbar-brand>&ndash;&gt;

          &lt;!&ndash;          <b-navbar-brand class="nav-light">&ndash;&gt;
          &lt;!&ndash;            <span class="nav-light">&ndash;&gt;
          &lt;!&ndash;              关注领取福利&ndash;&gt;
          &lt;!&ndash;            </span>&ndash;&gt;
          &lt;!&ndash;          </b-navbar-brand>&ndash;&gt;
          &lt;!&ndash;        </div>&ndash;&gt;
          <div class="navbar-search p-4 m-auto">
            <el-input placeholder="请输入内容">
              <template slot="prepend">
                课程
                &lt;!&ndash;            <el-select v-model="searchMode" va slot="prepend" placeholder="课程">&ndash;&gt;
                &lt;!&ndash;              <el-option label="课程" value="1"></el-option>&ndash;&gt;
                &lt;!&ndash;              <el-option label="学习计划" value="2"></el-option>&ndash;&gt;
                &lt;!&ndash;       <el-option label="其他" value="3"></el-option>&ndash;&gt;
                &lt;!&ndash;            </el-select>&ndash;&gt;
              </template>
              <el-button slot="append" style="color: white" icon="el-icon-search"></el-button>
            </el-input>
          </div>
          <div class="d-flex">
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
              <div class="mt-3 ml-2 d-flex justify-content-end pointer align-content-center">
                <span class="text-nowrap" @click="myStudies">我的学习</span>
                <el-badge :value="getShoppingSize" class="item-badge">
                  <svg class="small-menu" @click="toRouter"></svg>
                </el-badge>

                <el-badge :value="0" class="item-badge">
                  <div class="small-menu-notice"></div>
                </el-badge>

                <div class="elliptical"/>

                <el-popover
                    popper-class="custom-popover"
                    trigger="hover">
                  <div class="text-center">
                    <el-button class="buttonel" type="text" @click="goToSettings">个人中心</el-button>
                    <div>
                      <el-button class="buttonel" type="text" @click="doLogout">退出</el-button>
                    </div>
                  </div>


                  <div slot="reference" class="d-flex text-nowrap">
                    <div :loading="true">
                      {{ getUserName }}
                    </div>
                    <el-avatar id="avatar" shape="square" size="medium"
                               :src="getHeadImg"></el-avatar>

                  </div>
                </el-popover>
              </div>
            </b-collapse>
          </div>
        </b-container>
        &lt;!&ndash;      <div class="fill-box" v-show="showBtn">&ndash;&gt;
        &lt;!&ndash;        <div class="d-sm-flex d-none"&ndash;&gt;
        &lt;!&ndash;             style="justify-content: center;width: 72vw;align-items: center;align-content: center">&ndash;&gt;
        &lt;!&ndash;          <div class="p-1 homecursor" >&ndash;&gt;
        &lt;!&ndash;            <div>&ndash;&gt;
        &lt;!&ndash;              首页&ndash;&gt;
        &lt;!&ndash;              <div class="under-line"></div>&ndash;&gt;
        &lt;!&ndash;            </div>&ndash;&gt;
        &lt;!&ndash;          </div>&ndash;&gt;
        &lt;!&ndash;          &lt;!&ndash;          <div class="ml-5">&ndash;&gt;&ndash;&gt;
        &lt;!&ndash;          &lt;!&ndash;            <el-dropdown>&ndash;&gt;&ndash;&gt;
        &lt;!&ndash;          &lt;!&ndash;  <span class="el-dropdown-link">&ndash;&gt;&ndash;&gt;
        &lt;!&ndash;          &lt;!&ndash;    课程分类<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>&ndash;&gt;&ndash;&gt;
        &lt;!&ndash;          &lt;!&ndash;  </span>&ndash;&gt;&ndash;&gt;
        &lt;!&ndash;          &lt;!&ndash;              <el-dropdown-menu slot="dropdown">&ndash;&gt;&ndash;&gt;
        &lt;!&ndash;          &lt;!&ndash;                <el-dropdown-item>课程一</el-dropdown-item>&ndash;&gt;&ndash;&gt;
        &lt;!&ndash;          &lt;!&ndash;                <el-dropdown-item divided>课程二</el-dropdown-item>&ndash;&gt;&ndash;&gt;
        &lt;!&ndash;          &lt;!&ndash;              </el-dropdown-menu>&ndash;&gt;&ndash;&gt;
        &lt;!&ndash;          &lt;!&ndash;            </el-dropdown>&ndash;&gt;&ndash;&gt;
        &lt;!&ndash;          &lt;!&ndash;          </div>&ndash;&gt;&ndash;&gt;
        &lt;!&ndash;        </div>&ndash;&gt;
        &lt;!&ndash;      </div>&ndash;&gt;


      </b-navbar>

      &lt;!&ndash;    <b-container>
            <el-row type="flex" justify="space-between">


              <el-col :span="12">

              </el-col>

              <el-col :span="6">

                <section style="line-height: 25px;height: 20px">

                </section>
              </el-col>


              &lt;!&ndash;        <b-row>&ndash;&gt;

              &lt;!&ndash;        </b-row>&ndash;&gt;

              &lt;!&ndash;      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>&ndash;&gt;

              &lt;!&ndash;      <b-collapse id="nav-collapse" is-nav>&ndash;&gt;


              &lt;!&ndash;        &lt;!&ndash; Right aligned nav items &ndash;&gt;&ndash;&gt;
              &lt;!&ndash;        <b-navbar-nav class="auto">&ndash;&gt;

              &lt;!&ndash;    &ndash;&gt;

              &lt;!&ndash;          <b-nav-item-dropdown right>&ndash;&gt;
              &lt;!&ndash;            &lt;!&ndash; Using 'button-content' slot &ndash;&gt;&ndash;&gt;
              &lt;!&ndash;            <template v-slot:button-content>&ndash;&gt;
              &lt;!&ndash;              <em>User</em>&ndash;&gt;
              &lt;!&ndash;            </template>&ndash;&gt;
              &lt;!&ndash;            <b-dropdown-item href="#">Profile</b-dropdown-item>&ndash;&gt;
              &lt;!&ndash;            <b-dropdown-item href="#">Sign Out</b-dropdown-item>&ndash;&gt;
              &lt;!&ndash;          </b-nav-item-dropdown>&ndash;&gt;
              &lt;!&ndash;        </b-navbar-nav>&ndash;&gt;
              &lt;!&ndash;      </b-collapse>&ndash;&gt;

            </el-row>

            <div style="position: relative;top: 40px">
              <div>asd</div>
            </div>
          </b-container>&ndash;&gt;


      &lt;!&ndash;    </b-navbar>&ndash;&gt;

      &lt;!&ndash;    <el-header>
            <div class="header-right">
              <div class="home-icon" @click="userDialogVisible = true">&nbsp;</div>
              <div class="home-divider">
              </div>
              <el-popconfirm
                  title="您确定要退出吗?"
                  @confirm="doLogout">
                <div slot="reference" class="home-icon2">&nbsp;</div>
              </el-popconfirm>
            </div>
          </el-header>&ndash;&gt;

      <el-dialog title="用户信息" :visible.sync="userDialogVisible" width="700px">
        &lt;!&ndash; 在对话框中显示用户数据 &ndash;&gt;
        <div v-if="userData" class="user-info">
          <div class="user-info-item">
            <span class="label">用户ID:</span>
            <span class="value">{{ userData.id }}</span>
          </div>
          <div class="user-info-item">
            <span class="label">昵称:</span>
            <span class="value">{{ userData.nickname }}</span>
          </div>
          <div class="user-info-item">
            <span class="label">电话号码:</span>
            <span class="value">{{ userData.phone }}</span>
          </div>
          &lt;!&ndash; 其他需要显示的用户信息 &ndash;&gt;
        </div>

        &lt;!&ndash; 对话框的底部内容插槽 &ndash;&gt;
        <span slot="footer" class="dialog-footer">
          <el-button @click="userDialogVisible = false">关闭</el-button>
        </span>
      </el-dialog>
    </div>-->
</template>


<style scoped>


.user-info {
  margin-top: 20px;
}

.user-info-item {
  margin-bottom: 10px;
}

.label {
  font-weight: bold;
  margin-right: 10px;
}

.value {
  color: #333;
}


>>> .el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 150px);
  max-width: calc(100% - 30px);
  border-radius: 12px;
}

>>> .el-dialog .el-dialog__body {
  flex: 1;
  overflow: hidden;
}

#header-x {
  /*background-image: url('@/assets/image/header-x.png');*/
  /*height: 80px!important;*/
  width: 100%;
  background: transparent;
  color: white;
  transition: all .2s linear;
}

.left-box {
  color: white !important;
  font-weight: 700;
  font-size: 25px;
  /*transition: all .2s linear;*/
}


.left-box-2 {
  font-weight: lighter;
  text-align: left;
  color: white !important;
}

/deep/ .navbar-search, /deep/ .el-input__inner, /deep/ .el-input {
  border-radius: 24px !important;
  border: none !important;
  box-shadow: none !important;
  justify-content: center;
  height: 30px;
  background-color: #F6F7FB;
}

.navbar-search {
  margin: 5px 0;
}
.el-input__icon {
  line-height: 30px;
}


.small-menu {
  width: 29px;
  height: 27px;
  background-size: 80%;
  background-position-x: 7px;
  background-repeat: no-repeat;
  margin: 0 10px;
  cursor: pointer;
  background-image: url("@/assets/image/900-2.png");
}

.small-menu-notice {
  width: 25px;
  height: 27px;
  background-size: 80%;
  background-position-x: 7px;
  background-repeat: no-repeat;
  margin: 0 10px;
  cursor: pointer;
  background-image: url("@/assets/image/notice.png");
}

.item-badge /deep/ .el-badge__content {
  /*width: 17px;*/
  /*height: 17px;*/
  /*line-height: 17px;*/
  /*font-size: 12px;*/
  /*padding: 1px;*/
  margin: 0 10px;
  box-shadow: #FFAF24 0 0px 7px 0;
  background: linear-gradient(35deg, #FF8C00, #FFAF24);
  border: none;
}

.elliptical {
  width: 5px;
  height: 36px;
  background-size: 70%;
  margin: 0 15px;
  background-repeat: no-repeat;
  background-image: url("@/assets/image/elliptical.png");
}

#avatar {
  position: relative;
  bottom: 8px;
  left: 10px;
}

.el-dropdown-link {
  color: white;
  font-size: 17px;
  margin: 2px;
}

.fill-box {
  position: absolute;
  bottom: -4px;
  left: 0;
  right: 0;
  margin: 0 auto;
  justify-content: center;
  width: 100vw;
}

.buttonel:hover {
  background-color: rgba(158, 158, 158, 0.27);
  padding: 10px 20px;
  transition: all .2s linear;
}


.navbar-left-context {
  white-space: nowrap;
}

.navbar-left-context div:nth-child(1) {
  font-size: 18px;
}

.navbar-left-context div:nth-child(2) {
  font-size: 12px;
}

.btn-90-w {
  width: 80px;
  font-weight: bold;
}

.navbar-custom-class {
  box-shadow: #eaeaea 0 1px 2px 0;
}
</style>

<template>
  <div id="foot-bar" class="text-light p-4">
    <b-container>
      <b-row class="justify-content-center justify-content-lg-start">
        <b-col lg="3" md="5" cols="10" sm="12" class="d-none d-md-block" >
          <b-row>
            <b-col class="upperLevelText">关于我们</b-col>
          </b-row>
          <div class="upperLevel-color mt-3"></div>
          <b-row class="mt-3 about-text w-100 ml-0">
            厦门泓庚航海科技有限公司
            ,自2011年成立以来在航海电子领域不断深化,产品包括航海设备仿真,船电工程,IBS系统集成,VTS监控系统,信息系统集成等,并逐渐形成一套完整的设计、安装、调试、培训、维护一站式服务体系,获得国家软件企业认定证书、多项实用新型专利证书及软件产品登记证书,在航海电子领域草定了坚实的行业地位。
          </b-row>
        </b-col>


        <b-col lg="3" md="5" cols="10" sm="12"  @click="bindWeChat = true" >
          <b-row>
            <b-col class="upperLevelText text-center text-md-left">联系我们</b-col>
          </b-row>
          <div class="upperLevel-color mt-3  d-none d-md-block"></div>
          <b-row class="mt-3 small-text w-75 ml-0">
            电话：18005020034
          </b-row>

          <b-row class="mt-1 small-text w-100 ml-0">
            总部地址：福建省厦门市火炬高新区软件园三期溪山尾路67号801
          </b-row>
        </b-col>

        <b-col lg="3" md="5" cols="10" sm="12" class="d-none d-md-block">
          <b-row>
            <b-col class="upperLevelText">合作伙伴</b-col>
          </b-row>
          <div class="upperLevel-color mt-3"></div>
          <b-row class="mt-3 small-text w-75 ml-0">
            陆军军事交通学院镇江校区
          </b-row>
          <b-row class="mt-1 small-text w-75 ml-0">
            武警海警学院
          </b-row>
          <b-row class="mt-1 small-text w-100 ml-0">
            中国海事服务中心
          </b-row>
          <b-row class="mt-1 small-text w-100 ml-0">
            集美大学
          </b-row>
          <b-row class="mt-1 small-text w-100 ml-0">
            浙江海洋学院
          </b-row>
          <b-row class="mt-1 small-text w-100 ml-0">
            山东交通职业学院
          </b-row>
        </b-col>

        <b-col lg="3" md="5" cols="10" sm="12" @click="bindWeChat = true" class=" d-none d-md-block">
          <b-row>
            <b-col class="upperLevelText">关注我们</b-col>
          </b-row>
          <div class="upperLevel-color mt-3"></div>
          <b-row class="mt-3 small-text w-75 ml-0">
            <b-img :src="require(`@/assets/image/wxqr.jpg`)" alt="" width="150"/>
          </b-row>
        </b-col>
      </b-row>

      <div class="text-center mt-3 small-text pointer" @click="icp">
        © 2025 厦门泓庚航海科技 版权所有 闽ICP备15011792号-3
      </div>
    </b-container>

    <el-dialog :show-close="false" width="250px" center title="微信扫码关注" :visible.sync="bindWeChat">
      <div>
        <b-img :src="require(`@/assets/image/wxqr.jpg`)" alt="" fluid-grow/>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as req from "@/utils/request";
import netty from "@/utils/websocket";

export default {
  name: "footBar",
  data() {
    return {
      bindWeChat: false
    }
  },
  methods: {
    icp () {
      window.open('https://beian.miit.gov.cn/#/Integrated/index')
    }
  }
}
</script>

<style scoped>
#foot-bar {
  background: #2A325A;
  position: static;
  width: 100%;
  bottom: 0;
}

.small-text {
  font-size: .75em;
  color: rgba(255, 255, 255, 0.34);
}

.about-text {
  font-size: .75em;
  letter-spacing: 2px;
  color: rgba(255, 255, 255, 0.34);
}

.upperLevelText {
  font-size: 16pt;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.6);
}

.upperLevel-color {
  height: 2px;
  width: 35px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.4);
}

#avatar-wechat {
  position: relative;
  left: 5px;
  bottom: 4PX;
  cursor: pointer;
}

#avatar-weibo {
  position: relative;
  left: 15px;
  bottom: 4PX;
  cursor: pointer;
}

</style>
